'use client';

import { useAuth } from '@context/authContext';
import { useSiteInfo } from '@context/siteInfoContext';
import type {
    Maybe,
    SideMenuNavigationFragment,
    SideMenuStoreInfoFragment,
} from '@server/gql/graphql';
import type { ReactNode } from 'react';

import { cn } from '@/lib/utils';

import SideMenuNavigation from './SideMenuNavigation';
import { StoreInfo } from './StoreInfo';

export const DesktopMenu = ({
    slug,
    locale,
    storeData,
    navigations,
    showAccountButton,
    children,
    className,
}: {
    slug: string;
    locale: string | undefined;
    storeData?: Maybe<SideMenuStoreInfoFragment>;
    navigations?: Maybe<SideMenuNavigationFragment>[];
    showAccountButton?: boolean;
    children?: ReactNode;
    className?: string;
}) => {
    const { dictionary: globalDictionary } = useSiteInfo();
    const { logout } = useAuth();

    return (
        <nav
            className={cn(
                'hidden h-full flex-col divide-y border-r lg:col-span-3 lg:flex xl:col-span-2',
                className,
            )}>
            <div className="flex flex-col gap-y-5 px-5 md:py-10">
                {navigations
                    ? navigations?.map((navigation) => (
                          <SideMenuNavigation
                              key={navigation?.sys?.id}
                              slug={slug}
                              navigation={navigation}
                              locale={locale}
                          />
                      ))
                    : null}
                {showAccountButton ? (
                    <button
                        onClick={logout}
                        className="-mt-3 text-left text-sm hover:underline"
                        type="button">
                        {globalDictionary?.signOut}
                    </button>
                ) : null}
                {children}
            </div>
            {storeData ? (
                <StoreInfo
                    locale={locale}
                    storeData={storeData}
                    dictionary={globalDictionary}
                    className="hidden px-4 pb-4 pt-6 md:block"
                />
            ) : null}
        </nav>
    );
};
